<!-- eslint-disable -->
<template>
  <base-material-card
      icon="mdi-clipboard-text"
      title="Voir la liste des paiements"
      class="px-5 py-3"
    >
    <b-modal v-model="showDocModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Documents
      </template>
      <v-container class="py-0">
        <v-row>
          <v-col cols="12" md="12">
            <b-card title="Liste des documents renseignés">
              <v-row v-for="file in packageDetail.files" :key="file.file_name">
                <span style="padding:10px"><strong>{{file.doc_name}}:</strong><a target="_blank" style="margin-left:10px" :href="hostName+file.link">{{file.file_name}}</a></span>
              </v-row>
            </b-card>
          </v-col>
        </v-row>
      </v-container>
    </b-modal>
    <b-modal v-model="showPostModal" hide-footer size="lg">
      <template #modal-title>
        Détails : Prix total {{packageDetail.price}} - N° des colis {{ packageDetail.colis.length }}
      </template>
      <v-container class="py-0">
          <b-form @submit.prevent="showPostModal=false">
          <div style="overflow-y:auto,overflow-x:hidden">
            <label><strong>Liste des colis sélectionné:</strong></label>
            <v-row v-for="colis in packageDetail.colis" :key="colis.tracking_id">
              <v-col cols="12" md="3">
                <label><strong>Tracking ID:</strong> {{colis.tracking_id}} </label>
              </v-col>
              <v-col cols="12" md="3">
                <label><strong>Marchendise:</strong> {{colis.content}} </label>
              </v-col>
              <v-col cols="12" md="3">
                <label><strong>Wilaya d'arrivé:</strong> {{colis.to_wilaya}} </label>
              </v-col>
              <v-col cols="12" md="3">
                <label><strong>Prix:</strong> {{colis.colis_price}} </label>
              </v-col>
            </v-row>
          </div>
          <v-col cols="12" class="text-right">
            <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
              Fermer
            </v-btn>
          </v-col>
          </b-form>
      </v-container>
    </b-modal>
    <b-modal v-model="show2" @ok="getpaid()" title='CONFIRMATION DE PAIEMENT'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer le paiement</p>

    </b-modal>
    <b-modal v-model="show3" @ok="deletePackage" title='CONFIRMATION DE SUPPRESSION'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer la suppression</p>
    </b-modal>
    <!-- <b-modal v-model="show">
      <h3>{{infoshow.tracking_id}} :{{infoshow.content}}</h3><p>Clients name : {{infoshow.client_name}}</p><p>Phone Number : {{infoshow.client_phone}}</p>
      <p v-for="histori in infoshow.history" :key="histori.message">***{{moment(histori.creation_date).format('MM/DD/YYYY HH:mm')}} : {{histori.message}}</p>
    </b-modal> -->
    <b-modal v-model="show" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
    <div class="d-block text-center" style="background-color:white">
      <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
        <h4>Section des dates</h4>
        <strong>Date de création: </strong>{{serialiseDate(packageDetail.creation_date)}}<br>
        <strong>Date d'expédition: </strong>{{serialiseDate(packageDetail.shipping_date)}}<br>
        <strong>Date de livraison: </strong>{{serialiseDate(packageDetail.delivery_date)}}<br>
        <hr>
          <h4>Section d'historique</h4>
          <p v-for="histori in infoshow.history" :key="histori.message"><strong>{{moment(histori.creation_date).format('DD/MM/YYYY HH:mm')}}: </strong> {{histori.message}}</p>
        <hr>
        <h4>Section d'infomation de client</h4>
        <strong>Nom complet de client: </strong>{{packageDetail.client_name}}<br>
        <strong>N° Téléphone de client: </strong>{{packageDetail.client_phone}}<br>
        <strong>Nom complet de receveur: </strong>{{packageDetail.receiver_name}}<br>
        <strong>N° Téléphone de receveur: </strong>{{packageDetail.receiver_phone}}<br>
        <strong>Contenu de colis: </strong>{{packageDetail.content}}<br>
        <strong>Prix de colis: </strong>{{packageDetail.colis_price}}<br>
        <strong>Prix de livraison: </strong>{{parseInt(packageDetail.delivery_price)}}<br>
        <strong>Prix Total: </strong>{{parseInt(packageDetail.total_price)}}<br>
      </div>
    </div>
    <b-button class="mt-3" style="color:white" block @click="hideModal">Fermer</b-button>
  </b-modal>
  <b-modal v-model="showChangable" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
      <div style="text-align:center;">
        <v-btn class="mr-3" style="color:white;background-color:green" @click="makeChanges('Livrée')">Livrée</v-btn>
        <!-- <v-btn class="mr-3" style="color:white;background-color:#D50000" @click="makeChanges('Confirmé')">Confirmé</v-btn> -->
      </div>
    <hr>
  </b-modal>
  <!-- <b-modal v-model="showChangable" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
    <b-form @submit.prevent="makeChanges">
      <div class="d-block text-center" style="background-color:white">
        <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field required v-model="packageDetail.client_name" label="Nom de client" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field required v-model="packageDetail.client_phone" label="N° Téléphone" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field required v-model="packageDetail.price" label="Prix de colis" type="number" class="purple-input"/>
              </v-col>
            </v-row>
        </div>
      </div>
      <b-button class="mr-3 float-right" style="color:white;" @click="hideModal">Annuler</b-button>
      <b-button class="mr-3 float-right" style="color:white;" variant="success" type="submit">Confirmer</b-button>
    </b-form>
  </b-modal> -->
    <!-- <v-simple-table>
      <thead>
          <tr>
            <th class="primary--text">
              <strong>ID</strong>
            </th>
            <th class="primary--text">
              Produit
            </th>
            <th class="primary--text">
              Client
            </th>
            <th class="primary--text">
              Status
            </th>
            <th class="primary--text">
              Post
            </th>
            <th class="text-right primary--text">
              Prix
            </th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="pakage in packages" :key="pakage.package_id">
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')"><v-btn depressed small color="#37474F">{{pakage.package_id}}</v-btn></td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.content}}</td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.client_name}}</td>
            <td v-if="((pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')) && pakage.status == 'Livrée'"><v-btn depressed small color="green">{{pakage.status}}</v-btn></td>
            <td v-if="((pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')) && pakage.status == 'Created'"><v-btn depressed small color="blue">{{pakage.status}}</v-btn></td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.wilaya}}</td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' &&  wilaya_search == 'All')" class="text-right">
              {{pakage.price}}
            </td>
          </tr>

        </tbody>
    </v-simple-table> -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      @on-column-filter="filtering"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
      @on-row-click="RowClick">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <v-btn v-if="props.row.status == 'Created'" depressed small color="#616161">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Accepté'" depressed small color="#0096FF">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Effectué'" depressed small color="#104367">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Payé'" depressed small color="green">{{props.row.status}}</v-btn>
          <v-icon id="doc-btn" style="color:blue;margin-left:10px;float:right">mdi-file-document</v-icon>
          <span style="float:right">({{props.row.files.length}})</span>
        </span>
        <span v-else-if="props.column.field == 'payment_id'">
          <v-icon id='print-btn' style="margin-right:10px" color="primary">mdi-printer</v-icon>
          <v-btn id="tracking-id-btn" v-b-tooltip.hover :title="copyMessage" depressed small color="#37474F">{{props.row.payment_id}}</v-btn>
          <v-icon v-b-tooltip.hover title="Livraison à domicile" v-if="props.row.delivery_type=='Home'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon>
          <v-icon v-b-tooltip.hover title="Livraison gratuit" v-if="props.row.free_delivered==true" style="margin-left:10px;margin-right:10px;" color="primary">mdi-currency-usd-off</v-icon>
          <!-- <v-icon v-if="props.row.delivery_type=='Stop Desk'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon> -->
          <v-btn id="pay-btn" v-if="props.row.status == 'Livrée' && props.row.posttoprovider == false && props.row.free_delivered == false && props.row.price != 0" class="float-right" depressed small color="red">NOT PAID</v-btn>
          <v-btn v-if="props.row.status == 'Livrée' && props.row.posttoprovider == true && props.row.free_delivered == false && props.row.price != 0" depressed small color="green" class="float-right">GET PAID</v-btn>
          <!-- <v-btn v-if="props.row. == 'Livrée' && props.row.posttoprovider == true" depressed small color="green">GET PAID</v-btn> -->
        </span>
        <span v-else-if="props.column.field == 'price'">
          <span>{{props.row.price}}</span>
          <v-icon v-if="props.row.changable == true" id="changable-btn" v-b-tooltip.hover title="Cliquer pour changer" style="margin-left:10px;margin-right:10px;" color="primary">mdi-cog</v-icon>
          <!-- <b-form-input v-else id="changable-input" type="number" v-model="props.row.price" @keydown.native="makeChanges"></b-form-input> -->
        </span>
        <span v-else>{{props.row[props.column.field]}}</span>
      </template>
    </vue-good-table>
  </base-material-card>

</template>

<script>
/* eslint-disable */
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Dashboard',
  data () {
    return {
      hostName:null,
      showDocModal:false,
      // searchTerm: '',
      showPostModal:false,
      moment: moment,
      copyMessage: 'Cliquer pour copier',
      provider: null,
      pemail: null,
      fuckoff: null,
      posts: [],
      welcoming: null,
      packages: [],
      status_list: ['Created', 'Livrée','All'],
      wilaya_list: ['Alger', 'Annaba', 'Bejaia','All','Mascara'],
      produit_list: ['Phone', 'Ibad', 'Laptop','All'],
      status_search: 'All',
      wilaya_search: 'All',
      produit_search: 'All',
      search: null,
      packageDetail: {},
      columns: [
        {
          label:'ID Paiement',
          field:'payment_id',
          filterOptions: {enabled:true,placeholder: 'Entrer le id',}
        },
        {
          label:'Venduer Express',
          field:'express',
          filterOptions: {enabled:true,placeholder: 'Entrer Produit',}
        },
        {
          label:'Montant',
          field:'price',
          filterOptions: {enabled:true,placeholder: 'Entrer le Client',}
        },
        {
          label:'Status',
          field:'status',
          filterOptions: {enabled:true,placeholder: 'Choisir le status',filterDropdownItems:['Created','Livrée','EnRoute','Arrived','Chargement','Returned','Returned-ARV','Returned-DLI','Returned-ONGO']}
        },
      ],
      rows: [],
      willayas: [],
      show: false,
      showChangable: false,
      infoshow: [],
      show2 : false,
      show3: false,
      tr_id2: null,
      delivered: null,
      returned: null,
      created: null,
      engoing: null,
      enhold: null,
      setset: null,


    }

  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.hostName = process.env.VUE_APP_MEDIA_URL.replace('/media/','')
    // this.gettingwilayas()
    this.getPostCenters()
    // this.detectingprovider()
    this.getPayments()
    this.getStatusList()


  },
  methods: {
    getPostCenters() {
        axios.post('get-post-centers').then(response => {
          if (response.data.status == 'success') {
            let names= []
            response.data.data.forEach(element=> {
              names.push(element.name)
            })
            this.$set(this.columns[4].filterOptions, 'filterDropdownItems', names);
          }
        })
      },
    getpaid(){
      axios.post('getpaid',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.tr_id2,
        setset: this.setset,
      }).then(response => {
        this.getPayments()
      })

    },
    copyTrackingId(row) {
      this.$clipboard(row.payment_id);
      this.copyMessage = 'Copié!'
      setTimeout(() => {
        this.copyMessage = 'Cliquer pour copier'
      }, 2000);
    },
    deletePackage() {
      axios.post('deletecolis',{
          auth_token: localStorage.getItem('auth_token'),
          tr_id: this.packageDetail.tracking_id,
        }).then(response => {
          this.getPayments()
        })
    },
    makeChanges(status) {
      axios.post('provider-change-delivery',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.packageDetail.tracking_id,
        status:status,
      }).then(response => {
        if (response.data.status == 'success')
          this.getPayments()
          this.showChangable = false
      })
    },
    RowClick(params){
      this.packageDetail = params.row
      if (params.event.target.id == "history-btn"){
        this.show3 = true
      }
      else if (params.event.target.id == "doc-btn") {
        this.showDocModal=true;
        this.packageDetail=params.row
      }
      else if (params.event.target.id == 'status-btn' || params.event.target.parentNode.id == 'status-btn' || params.event.target.parentNode.parentNode.id == 'status-btn' || params.event.target.parentNode.parentNode.parentNode.id == 'status-btn') {
        this.showChangable = true
      }
      else if (params.event.target.id == 'print-btn' || params.event.target.parentNode.id == 'print-btn') {
        axios.post('print-pdf-express-facture', {
          auth_token: localStorage.getItem('auth_token'),
          tracking_id: this.packageDetail.payment_id
        }).then(response=> {
          if (response.data.status == 'success') {
            const link = document.createElement('a')
            link.target = '_blank'
            link.href = process.env.VUE_APP_MEDIA_URL+"document.pdf"
            link.setAttribute('download', "document.pdf")
            document.body.appendChild(link)
            link.click()
          }
        })
      }
      else if (params.event.target.id == 'changable-btn') {
        this.showChangable = true
      }
      else if (params.event.target.id == 'tracking-id-btn' || params.event.target.parentNode.id == 'tracking-id-btn') {
        this.copyTrackingId(params.row)
      }
      else if (params.event.target.id == "pay-btn" || params.event.target.parentNode.id == 'pay-btn'){
        this.show2 = true
        this.tr_id2 = params.row.tracking_id
        this.setset = 'normal'
      }
      else{
        this.showPostModal = true
      }
    },
    gettingwilayas(){
      axios.post('get-wilayas').then(response => {

        for (let i=0;i<response.data.data.length;i++){
          this.willayas.push(response.data.data[i].name)
        }
        // this.columns[4].filterOptions.filterDropdownItems= this.willayas
        this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.willayas);
      })

    },
    filtering(params) {
      console.log(params)
    },
    getStatusList() {
      axios.post('get-status-list').then(response => {
        if (response.data.status == 'success') {
          this.items = response.data.data
        }
      })
    },
    handleModalHide(params) {
      this.show = false
      this.showChangable = false
    },
    hideModal(params) {
      this.show = false
      this.showChangable = false
    },
    serialiseDate(date) {
      if (date != null && date!= undefined)
        return moment(date).format('DD/MM/YYYY HH:mm')
      else
        return 'N/A'
    },
   detectingprovider(){
        axios.post('detectingprovider',{
          auth_token: localStorage.getItem('auth_token'),
        }).then(response => {
          this.provider = response.data.data.provider.name
          this.pemail = response.data.data.provider.email
          this.fuckoff = response.data.status
          for (let i=0;i<response.data.data.posts.length;i++){
            this.posts.push({code:response.data.data.posts[i].code,name:response.data.data.posts[i].name})
          }
        })
      },
    getPayments() {
      axios.post('get-express-payments',{
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        this.rows=response.data.data.packages
      })
    },
    getingpackages(){
      axios.post('getingpackages',{
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        // console.log(response.data.data.packages)
        // for (let i=0;i<response.data.data.packages.length;i++){
        //   this.packages.push({
        //     package_id:response.data.data.packages[i].tracking_id,
        //     content:response.data.data.packages[i].content,
        //     status:response.data.data.packages[i].status,
        //     wilaya:response.data.data.packages[i].wilaya,
        //     client_name:response.data.data.packages[i].client_name,
        //     price:response.data.data.packages[i].price,

        //   })

        // }
        this.rows=response.data.data.packages
        this.infoshow=response.data.data.packages
        this.delivered=response.data.data.Livrée
        this.returned=response.data.data.Returned
        this.created=response.data.data.Created
        this.engoing=response.data.data.EnRoute
        this.enhold=response.data.data.Chargement
      })
    }
  },
}
</script>
<style>

</style>